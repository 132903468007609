<template>
  <b-container fluid>
    <iq-card>
      <template v-slot:headerTitle>
        <h4 class="card-title">{{$t('elearning_config.guest_trainer')}}</h4>
      </template>
      <template v-slot:body>
        <b-row>
          <b-col lg="6" md="6" sm="12" xs="12">
            <b-form-group
              class="row"
              label-cols-sm="3"
              :label="$t('globalTrans.name')"
              label-for="training_type"
            >
              <b-form-input
                  id="name"
                  type="text"
                  v-model="search.name"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
              <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
          </b-col>
        </b-row>
      </template>
    </iq-card>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('elearning_config.guest_trainer') }} {{ $t('globalTrans.list') }}</h4>
          </template>
          <template v-slot:headerAction>
            <b-button variant="primary btn_add_new" v-b-modal.modal-form @click="resetId">
              <i class="fas fa-plus"></i>{{ $t('globalTrans.add_new') }}
            </b-button>
          </template>
          <template v-slot:body>
            <b-overlay :show="loadingState">
              <b-row>
                <b-col md="12" class="table-responsive">
                  <b-table thead-class="table_head" striped bordered hover :items="listData" :fields="columns"
                    aria-hidden="loading | listReload ? 'true' : null" :emptyText="$t('globalTrans.noDataFound')"
                    show-empty>
                    <template v-slot:cell(serial)="data">
                      {{ $n(data.index + pagination.slOffset) }}
                    </template>
                    <template v-slot:cell(guest_mobile)="data">
                      {{ $i18n.locale === 'bn' ? '০' : '0' }}{{ $n(data.item.guest_mobile, { useGrouping: false }) }}
                    </template>
                    <template v-slot:cell(org_name)="data">
                      <span v-if="data.item.profession_type == 1">{{ currentLocale === 'bn' ? data.item.org_name_bn : data.item.org_name_en }}</span>
                      <span v-if="data.item.profession_type == 2 || data.item.profession_type == 3">{{ currentLocale === 'bn' ? data.item.other_org_name_bn : data.item.other_org_name }}</span>
                    </template>
                    <template v-slot:cell(status)="data">
                      <span class="badge badge-success" v-if="data.item.status == 1">{{$t('globalTrans.active')}}</span>
                      <span class="badge badge-danger" v-else>{{$t('globalTrans.inactive')}}</span>
                    </template>
                    <template v-slot:cell(action)="data">
                      <a href="javascript:" class="btn_table_action table_action_view" title="View Details"
                        v-b-modal.details @click="details(data.item)">
                        <i class="fas fa-eye"></i>
                      </a>
                      <a href="javascript:" class="btn_table_action table_action_edit" title="View Form"
                        v-b-modal.modal-form @click="edit(data.item)">
                        <i class="ri-ball-pen-fill"></i>
                      </a>
                      <!-- <a href="javascript:" class="btn_table_action table_action_status" title="Change Status"
                        v-if="data.item.status === 1" @click="remove(data.item)">
                        <i class="fas fa-toggle-on"></i>
                      </a>
                      <a href="javascript:" class="btn_table_action table_action_toggle" title="Change Status"
                        v-if="data.item.status === 2" @click="remove(data.item)">
                        <i class="fa fa-toggle-off"></i>
                      </a> -->
                    </template>
                  </b-table>
                  <b-pagination align="center" v-model="pagination.currentPage" :per-page="pagination.perPage"
                    :total-rows="pagination.totalRows" @input="searchData" />
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-modal id="modal-form" size="lg" :title="formTitle" hide-footer :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <Form :id="editItemId" :key="editItemId"/>
    </b-modal>
    <b-modal hide-footer="true" id="details" size="lg" :title="$t('elearning_config.guest_trainer') + ' ' + $t('globalTrans.details')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
         <Details :item="items" :key="viewitemId"/>
    </b-modal>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'
import Form from './Form.vue'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { guestTrainerList, guestTrainerToggleStatus } from '../../api/routes'
import Details from './Details'

export default {
  mixins: [ModalBaseMasterList],
  components: {
    Form, Details
  },
  data () {
    return {
      search: {
        name: '',
        limit: 20
      },
      viewitemId: 0,
      editItemId: 0,
      items: [],
      trainingTypeList: [],
      professional_info: [],
      actions: {
        edit: true,
        details: true,
        toogle: false,
        delete: false
      },
      labelData: [
        { labels: 'globalTrans.sl_no', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 1, thStyle: { width: '5%' } },
        { labels: 'globalTrans.name', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 2, thStyle: { width: '15%' } },
        { labels: 'globalTrans.organization', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 2, thStyle: { width: '20%' } },
        { labels: 'globalTrans.designation', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 3, thStyle: { width: '15%' } },
        { labels: 'globalTrans.mobile', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 3, thStyle: { width: '15%' } },
        // { labels: 'globalTrans.status', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 4, thStyle: { width: '5%' } },
        { labels: 'globalTrans.action', class: 'text-left', show: '1', order: 5, thStyle: { width: '10%' } }
      ]
    }
  },
  computed: {
    orgList () {
      return this.$store.state.CommonService.commonObj.componentOrgList.filter(item => item.status === 1)
    },
    orgProfileList () {
      return this.$store.state.commonObj.organizationProfileList
    },
    formTitle () {
      return (this.editItemId === 0) ? this.$t('elearning_config.guest_trainer') + ' ' + this.$t('globalTrans.entry') : this.$t('elearning_config.guest_trainer') + ' ' + this.$t('globalTrans.modify')
    },
    columns () {
      const labelData = this.labelData
      const labels = labelData.map((item, index) => {
        return Object.assign(item, { label: this.$t(item.labels, this.$i18n.locale) })
      })
      let keys = []
      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'serial' },
          { key: 'name_bn' },
          { key: 'org_name' },
          { key: 'designation_bn' },
          { key: 'guest_mobile' },
          // { key: 'status' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'serial' },
          { key: 'name' },
          { key: 'org_name' },
          { key: 'designation_en' },
          { key: 'guest_mobile' },
          // { key: 'status' },
          { key: 'action' }
        ]
      }
      return labels.map((item, index) => {
        return Object.assign(item, keys[index])
      })
    }
  },
  created () {
    this.loadData()
  },
  mounted () {
    core.index()
  },
  watch: {
    'search.limit': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.loadData()
      }
    },
    'search.org_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.trainingTypeList = this.getTrainingTypeList(newVal)
      } else {
        this.trainingTypeList = []
      }
    }
  },
  methods: {
    editMethodInParent (item, type) {
      if (type === 1) {
        this.edit(item)
      } else if (type === 2) {
        this.changeStatus(12, trainingElearningServiceBaseUrl, guestTrainerToggleStatus, item)
      } else if (type === 3) {
        this.details(item)
      }
    },
    getTrainingTypeList (id) {
      return this.$store.state.TrainingElearning.commonObj.trainingTypeList.filter(item => item.status === 1 && item.org_id === id)
    },
    details (item) {
      this.items = item
    },
    async searchData () {
      this.loadData()
    },
    async loadData () {
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      const result = await RestApi.getData(trainingElearningServiceBaseUrl, guestTrainerList, params)
      if (result.success) {
        const listData = result.data.data.map((item, index) => {
          const orgData = {}
            orgData.other_org_name = item.professional_info.other_org_name
            orgData.other_org_name_bn = item.professional_info.other_org_name_bn
            orgData.profession_type = item.professional_info.profession_type
            orgData.designation_en = item.professional_info.designation_en
            orgData.designation_bn = item.professional_info.designation_bn
            orgData.org_id = item.professional_info.org_id
            const orgObj = this.$store.state.commonObj.organizationProfileList.find(org => org.value === parseInt(item.professional_info.org_id))
            if (typeof orgObj !== 'undefined') {
              orgData.org_name_en = orgObj.text_en
              orgData.org_name_bn = orgObj.text_bn
            } else {
              orgData.org_name_en = ''
              orgData.org_name_bn = ''
            }
          return Object.assign({}, item, { serial: index }, orgData)
        })
        this.$store.dispatch('setList', listData)
        this.paginationData(result.data, this.search.limit)
      } else {
        this.$store.dispatch('setList', [])
        this.paginationData([])
      }
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    }
  }
}
</script>
